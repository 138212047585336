<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-overlay opacity="0.95" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <notification-message :message.sync="errorMessage" />
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto">
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__zoomIn"
          >
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img :src="logoSnappy" width="45" height="45" />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3>
                Email Verification
                {{ errorMessage ? 'Unsuccessful' : 'Successful' }}
              </h3>
              <h5 class="font-weight-medium">
                {{
                  errorMessage
                    ? 'Oh, we are unable to verify you email, these may occur because the link has expired, or you email has been verified'
                    : 'You have successful verified your email address, Happy Trading!'
                }}
              </h5>
              <!-- <v-btn
                v-if="errorMessage == null"
                class="mt-5"
                color="primary"
                @click="resendEmail"
                >Resend Email</v-btn
              > -->
              <v-btn
                v-if="errorMessage == null"
                to="/login"
                class="mt-5"
                rounded
                color="primary"
                >Login</v-btn
              >
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logoSnappy from '../../assets/logo.png'
import api from '../../api/users/auth'
import notificationMessage from '../../components/notification/notificationMessage.vue'

export default {
  components: { notificationMessage },
  data() {
    return {
      errorMessage: null,
      logoSnappy,
      code: '',
      loading: false,
      token: ''
    }
  },
  methods: {
    async verifyEmail() {
      const res = await api.auth().verifyEmail(this.token)
      if (res.error) {
        if (
          res.errorMessage.message === 'This email has already been verified.'
        ) {
          this.loading = false
          return
        }
        this.errorMessage = res.errorMessage.message
        this.loading = false
      }
      this.errorMessage = null
      this.loading = false
    }
  },
  mounted() {
    this.loading = true
    this.token = this.$route.params.token
    setTimeout(() => {
      this.verifyEmail()
    }, 2000)
  }
}
</script>

<style></style>
